import React from 'react'
import { Link } from 'react-router-dom'
import './SectionStyle.scss'

import Image from 'components/general/Image'

import { ReactComponent as ArrowRight } from 'assets/vectors/general/arrow-right.svg'

const SectionDescription = ({ data }) => {
  return (
    <section className="section section-about">
      <div className="container">
        <div className="flex-wrapper">
          <div className="left">
            <div className="left-description" appear="0.3">
              <p
                dangerouslySetInnerHTML={{
                  __html: data.page.about.description,
                }}
              ></p>
            </div>

            <div className="left-block">
              <div className="left-block--title" appear="0.3">
                {data.page.about.awards.title}
              </div>

              <div
                className="left-block--description"
                appear="0.3"
                dangerouslySetInnerHTML={{
                  __html: data.page.about.awards.description,
                }}
              ></div>
            </div>

            <div className="left-cta" appear="0.3">
              <a
                href={data.page.about.curriculum.link}
                title={data.page.about.curriculum.title}
                aria-label={data.page.about.curriculum.title}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>{data.page.about.curriculum.title}</span>
                <ArrowRight />
              </a>
            </div>
          </div>

          <div className="right">
            <div className="wrapper">
              <div className="wrapper-description">
                <div className="wrapper-description--line" appear="0.3">
                  {'Art Director &'.split(' ').map((item, index) => (
                    <span key={`hero-wrapper-1-${index}`}>{item}</span>
                  ))}
                </div>

                <div className="wrapper-description--line" appear="0.3">
                  {'Motion Graphic Designer'.split(' ').map((item, index) => (
                    <span key={`hero-wrapper-2-${index}`}>{item}</span>
                  ))}
                </div>

                <div className="wrapper-description--line" appear="0.3">
                  {'Mineiro em Belo Horizonte'.split(' ').map((item, index) => (
                    <span key={`hero-wrapper-3-${index}`}>{item}</span>
                  ))}
                </div>

                <div className="wrapper-description--links" appear="0.3">
                  <span>
                    <Link to="/projetos" title="Projetos" aria-label="Projetos">
                      Working
                    </Link>
                  </span>

                  <span>/</span>

                  <span>
                    <a href="mailto:contato@pinheiro.work">Mail</a>
                  </span>

                  <span>/</span>

                  <span>
                    <a
                      href="https://www.instagram.com/matheuspinheiro/"
                      title="Ir para Instagram"
                      aria-label="Ir para Instagram"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      IG
                    </a>
                  </span>

                  <span>/</span>

                  <span>
                    <a
                      href="https://www.linkedin.com/in/matheuspinhero"
                      title="Ir para Linkedin"
                      aria-label="Ir para Linkedin"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Linkedin
                    </a>
                  </span>
                </div>
              </div>
            </div>

            <div className="right-partners">
              <div className="right-partners--title" appear="0.3">
                {data.page.about.partners.title}
              </div>

              <div className="right-partners--items">
                {data.page.about.partners.partners.map((item, index) => (
                  <div
                    className="item"
                    appear="0.3"
                    appear-delay={100 * index}
                    key={`about-partners-${index}`}
                  >
                    <div className="item-image">
                      <Image url={item.image} />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="right-disciplines">
              <div className="right-disciplines--title" appear="0.3">
                {data.page.about.disciplines.title}
              </div>

              <div className="right-disciplines--items">
                {data.page.about.disciplines.content.map((item, index) => (
                  <div
                    className="item"
                    appear="0.3"
                    appear-delay={100 * index}
                    key={`about-disciplines-${index}`}
                  >
                    <div className="item-title">{item.title}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionDescription
