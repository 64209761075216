import React, { useEffect } from 'react'
import './PreloaderStyle.scss'

import { ReactComponent as LogoIcon } from 'assets/vectors/general/logo-preloader.svg'

const Preloader = () => {
  useEffect(() => {
    let perfData = window.performance.timing
    let EstimatedTime = -(perfData.loadEventEnd - perfData.navigationStart)
    let time = parseInt((EstimatedTime / 1000) % 60) * 250
    let start = 0
    let end = 100
    let duration = time

    const animate_progress = (start, end, duration) => {
      let range = end - start
      let current = start
      let increment = end > start ? 1 : -1
      let stepTime = Math.abs(Math.floor(duration / range))

      window.scrollTo(0, 0)

      let timer = setInterval(function () {
        current += increment

        const new_current = Math.abs(current - 100)

        const preloader = document.querySelector('#main-preloader')
        const progress = preloader.querySelector('.progress')

        progress.style.clipPath = `polygon(0% ${new_current}%, 100% ${new_current}%, 100% 100%, 0% 100%)`

        if (current === end) {
          clearInterval(timer)

          setTimeout(function () {
            const body = document.querySelector('body')

            body.dispatchEvent(new Event('pageChanged'))
            body.classList.remove('not-scrollable')
            preloader.classList.add('remove')
          }, 700)
        }
      }, stepTime)
    }

    setTimeout(() => animate_progress(start, end, duration), 1000)
  }, [])

  return (
    <div id="main-preloader">
      <div className="logo">
        <LogoIcon />
      </div>

      <div className="progress">
        <LogoIcon />
      </div>
    </div>
  )
}

export default Preloader
