import React from 'react'
import './SectionStyle.scss'

import Image from 'components/general/Image'

import { ReactComponent as HeadlineIcon } from 'assets/vectors/general/partners-headline.svg'

const SectionPartners = ({ data }) => {
  return (
    <section className="section section-partners">
      <div className="container">
        <div className="headline">
          <div className="headline-wrapper" appear="0.3">
            <div className="headline-title">{data.page.partners.title}</div>

            <HeadlineIcon />
          </div>

          <div className="headline-items">
            {data.page.partners.content.map((item, index) => (
              <div
                className="item"
                appear="0.3"
                appear-delay={150 * (index + 1)}
                key={`home-partners-${index}`}
              >
                <div className="item-image">
                  <Image url={item.image} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionPartners
