import React from 'react'
import { Link } from 'react-router-dom'
import { Parallax } from 'react-scroll-parallax'
import './SectionStyle.scss'

import Image from 'components/general/Image'

import { ReactComponent as ArrowRight } from 'assets/vectors/general/arrow-right.svg'

const SectionProjects = ({ data }) => {
  console.log(data)

  return (
    <section className="section section-projects">
      <div className="container">
        <div className="flex-wrapper">
          {data.page.projects.listing.map((item, index) => (
            <div className="project" key={`projects-item-${index}`}>
              <Link
                to={`/projeto/${item.permalink}`}
                className="project-link"
                title="Ver projeto"
                aria-label="Ver projeto"
              >
                Ver projeto
              </Link>

              <div className="project-image">
                <Parallax
                  className="project-image--block"
                  scale={[1.5, 1]}
                  rootMargin={{
                    top: 0,
                    bottom: -300,
                  }}
                >
                  <Image
                    url={item.image.image}
                    webp={item.image.imageWEBP}
                    avif={item.image.imageAVIF}
                  />
                </Parallax>
              </div>

              <div className="project-content">
                <div className="project-content--title">{item.title}</div>

                <div className="project-content--arrow">
                  <ArrowRight />
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="more">
          <div className="more-button" appear="0.3">
            <Link
              to="/contato"
              title={data.page.projects.textButton}
              aria-label={data.page.projects.textButton}
            >
              <span>{data.page.projects.textButton}</span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionProjects
