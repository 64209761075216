import { useEffect } from 'react'

const Parallax = () => {
  const changeValue = (entries) => {
    entries.forEach((entry) => {
      const item = entry.target
      if (entry.isIntersecting) {
        if (item.getAttribute('parallax-visible') !== 'true') {
          item.setAttribute('parallax-visible', 'true')
        }
      } else {
        if (item.getAttribute('parallax-visible') !== 'false') {
          item.setAttribute('parallax-visible', 'false')
        }
      }
    })
  }

  useEffect(() => {
    window.addEventListener('wheel', function (e) {
      const deltaY = e.deltaY
      let value = 0

      if (deltaY > 0) {
        value = Math.abs(deltaY / 2500)
      } else {
        value = Math.abs(deltaY / 3500)
      }

      const parallax = document.querySelectorAll('[parallax-visible="true"]')

      if (parallax.length === 0) return

      for (let i = 0; i < parallax.length; i++) {
        const item = parallax[i]
        const current_value = Number(item.getAttribute('parallax-transform'))

        if (current_value > 1.5) {
          item.setAttribute('parallax-transform', 1.5)
          item.style.transform = `scale(1.5)`
        } else if (current_value < 1) {
          item.setAttribute('parallax-transform', 1)
          item.style.transform = `scale(1)`
        } else {
          if (deltaY > 0) {
            let new_value = current_value - value

            if (new_value <= 1.00001) new_value = 1

            item.setAttribute('parallax-transform', new_value)
            item.style.transform = `scale(${new_value})`
          } else if (deltaY < -0) {
            let new_value = current_value + value

            if (new_value >= 1.500001) new_value = 1.5

            item.setAttribute('parallax-transform', new_value)
            item.style.transform = `scale(${new_value})`
          }
        }
      }
    })
  }, [])

  useEffect(() => {
    const body = document.querySelector('body')

    const parallax = () => {
      const elements = document.querySelectorAll('[parallax]')

      for (let i = 0; i < elements.length; i++) {
        const item = elements[i]

        item.setAttribute('parallax-transform', '2')
        item.style.transform = `scale(2)`

        new window.IntersectionObserver((entries) => changeValue(entries), {
          threshold: 0,
        }).observe(item)
      }
    }

    body.addEventListener('pageChanged', () => parallax())
  }, [])

  return ''
}

export default Parallax
