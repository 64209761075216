import React, { useEffect, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import './SectionStyle.scss'

const SectionHero = ({ data }) => {
  const section = useRef(null)

  useEffect(() => {
    if (!section.current) return

    const element = section.current

    element.addEventListener('appeared', function () {
      const video = element.querySelectorAll('video')

      if (video.length) {
        video[0].play()
      }
    })
  }, [section])

  return (
    <section className="section section-hero" appear="0" ref={section}>
      <div className="background">
        {isMobile ? (
          <img src={data.page.hero.gif} alt="Imagem - Matheus Pinheiro" />
        ) : (
          <video preload="metadata" muted loop>
            <source src={data.page.hero.videoWEBM} type="video/webm" />
            <source src={data.page.hero.videoMP4} type="video/mp4" />
          </video>
        )}
      </div>
    </section>
  )
}

export default SectionHero
