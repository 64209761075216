import React, { useRef, useState } from 'react'
import './SectionStyle.scss'

import { ReactComponent as HeadlineIcon } from 'assets/vectors/general/services-headline.svg'
import ServicesImage from 'assets/images/general/services-image.gif'

const SectionServices = () => {
  const [hover, setHover] = useState(false)
  const images = useRef(null)

  const setupImages = (e) => {
    const element = images.current
    const positionY = e.clientY
    const positionX = e.clientX

    element.style.willChange = `transform`
    setTimeout(function () {
      element.style.transform = `translate(${positionX}px, ${positionY}px)`
    }, 100)
  }

  return (
    <section
      className="section section-services"
      onMouseMove={(e) => setupImages(e)}
    >
      <div className="images" ref={images}>
        <div className={hover === 1 ? 'image active' : 'image'}>
          <img src={ServicesImage} alt="Imagem - Matheus Pinheiro" />
        </div>

        <div className={hover === 2 ? 'image active' : 'image'}>
          <img src={ServicesImage} alt="Imagem - Matheus Pinheiro" />
        </div>

        <div className={hover === 3 ? 'image active' : 'image'}>
          <img src={ServicesImage} alt="Imagem - Matheus Pinheiro" />
        </div>

        <div className={hover === 4 ? 'image active' : 'image'}>
          <img src={ServicesImage} alt="Imagem - Matheus Pinheiro" />
        </div>

        <div className={hover === 5 ? 'image active' : 'image'}>
          <img src={ServicesImage} alt="Imagem - Matheus Pinheiro" />
        </div>

        <div className={hover === 6 ? 'image active' : 'image'}>
          <img src={ServicesImage} alt="Imagem - Matheus Pinheiro" />
        </div>
      </div>

      <div className="container">
        <div className="headline" appear="0.3">
          <div className="headline-title">SERVICES</div>

          <HeadlineIcon />
        </div>

        <div className="flex-wrapper" appear="0.3">
          <div className="wrapper">
            <div
              className="item"
              onMouseEnter={() => setHover(1)}
              onMouseLeave={() => setHover(false)}
            >
              <div className="item-counter">#01</div>

              <div className="item-title">Art Director</div>

              <div className="item-description">
                <p>
                  Identidade Visual
                  <br />
                  Posicionamento
                  <br />
                  Storyboard
                </p>
              </div>
            </div>

            <div
              className="item"
              onMouseEnter={() => setHover(2)}
              onMouseLeave={() => setHover(false)}
            >
              <div className="item-counter">#02</div>

              <div className="item-title">Filter Creator</div>

              <div className="item-description">
                <p>
                  Interação
                  <br />
                  Games
                  <br />
                  Color Filters
                </p>
              </div>
            </div>

            <div
              className="item"
              onMouseEnter={() => setHover(3)}
              onMouseLeave={() => setHover(false)}
            >
              <div className="item-counter">#03</div>

              <div className="item-title">Art Director</div>

              <div className="item-description">
                <p>
                  Identidade Visual
                  <br />
                  Posicionamento
                  <br />
                  Storyboard
                </p>
              </div>
            </div>

            <div
              className="item"
              onMouseEnter={() => setHover(4)}
              onMouseLeave={() => setHover(false)}
            >
              <div className="item-counter">#04</div>

              <div className="item-title">Filter Creator</div>

              <div className="item-description">
                <p>
                  Interação
                  <br />
                  Games
                  <br />
                  Color Filters
                </p>
              </div>
            </div>

            <div
              className="item"
              onMouseEnter={() => setHover(5)}
              onMouseLeave={() => setHover(false)}
            >
              <div className="item-counter">#01</div>

              <div className="item-title">Art Director</div>

              <div className="item-description">
                <p>
                  Identidade Visual
                  <br />
                  Posicionamento
                  <br />
                  Storyboard
                </p>
              </div>
            </div>

            <div
              className="item"
              onMouseEnter={() => setHover(6)}
              onMouseLeave={() => setHover(false)}
            >
              <div className="item-counter">#02</div>

              <div className="item-title">Filter Creator</div>

              <div className="item-description">
                <p>
                  Interação
                  <br />
                  Games
                  <br />
                  Color Filters
                </p>
              </div>
            </div>

            <div
              className="item"
              onMouseEnter={() => setHover(7)}
              onMouseLeave={() => setHover(false)}
            >
              <div className="item-counter">#03</div>

              <div className="item-title">Art Director</div>

              <div className="item-description">
                <p>
                  Identidade Visual
                  <br />
                  Posicionamento
                  <br />
                  Storyboard
                </p>
              </div>
            </div>

            <div
              className="item"
              onMouseEnter={() => setHover(8)}
              onMouseLeave={() => setHover(false)}
            >
              <div className="item-counter">#04</div>

              <div className="item-title">Filter Creator</div>

              <div className="item-description">
                <p>
                  Interação
                  <br />
                  Games
                  <br />
                  Color Filters
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionServices
