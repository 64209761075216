import React, { useState, useEffect } from 'react'
import api from 'utils/api'
import './ContactStyle.scss'

import Header from 'components/general/Header'

import SectionHero from 'components/contact/SectionHero'

const Contact = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})

  useEffect(() => {
    if (loading) return
    setLoading(true)

    const url = `?action=load_page_fields&page=contact`

    api.get(url).then((response) => {
      setData(response.data)
    })
  }, [loading])

  return (
    <div id="root-contact" className="root-page">
      {data.page && (
        <>
          <Header data={data.options} />

          <main id="page-contact" className="page">
            <SectionHero data={data} />
          </main>
        </>
      )}
    </div>
  )
}

export default Contact
