import React from 'react'
import './SectionStyle.scss'

import Image from 'components/general/Image'

import { ReactComponent as HeadlineIcon } from 'assets/vectors/general/laboratory-headline.svg'
import { ReactComponent as ArrowRight } from 'assets/vectors/general/arrow-right.svg'
import { ReactComponent as IconPlay } from 'assets/vectors/laboratory/laboratory-icon-play.svg'

import ProjectImage3 from 'assets/images/general/project-image-3.jpg'

const SectionLaboratory = ({ data }) => {
  return (
    <section className="section section-laboratory">
      <div className="container">
        <div className="headline" appear="0.3">
          <div className="headline-title">{data.page.laboratory.title}</div>

          <HeadlineIcon />
        </div>

        <div className="flex-wrapper" appear="0.3">
          <div className="item">
            <a
              href="#link"
              className="item-link"
              title="Ver projeto"
              aria-label="Ver projeto"
            >
              Ver projeto
            </a>

            <div className="item-video">
              <div className="item-video--image">
                <Image url={ProjectImage3} />
              </div>

              <div className="item-video--play">
                <IconPlay />
              </div>
            </div>

            <div className="item-content">
              <div className="item-content--title">Open Co Animations</div>

              <div className="item-content--arrow">
                <button type="button" title="Ver mais" aria-label="Ver mais">
                  <ArrowRight />
                </button>
              </div>
            </div>
          </div>

          <div className="item">
            <a
              href="#link"
              className="item-link"
              title="Ver projeto"
              aria-label="Ver projeto"
            >
              Ver projeto
            </a>

            <div className="item-video">
              <div className="item-video--image">
                <Image url={ProjectImage3} />
              </div>

              <div className="item-video--play">
                <IconPlay />
              </div>
            </div>

            <div className="item-content">
              <div className="item-content--title">Open Co Animations</div>

              <div className="item-content--arrow">
                <button type="button" title="Ver mais" aria-label="Ver mais">
                  <ArrowRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionLaboratory
