import React, { useState, useEffect } from 'react'
import api from 'utils/api'
import { Redirect } from 'react-router-dom'
import './ProjectStyle.scss'

import Header from 'components/general/Header'
import Footer from 'components/general/Footer'

import SectionHero from 'components/project/SectionHero'
import SectionContent from 'components/project/SectionContent'
import SectionRelated from 'components/project/SectionRelated'

const Project = (params) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({ code: 1 })

  useEffect(() => {
    if (loading) return
    setLoading(true)

    const project = params.match.params.projeto
    const url = `?action=load_project&project=${project}`

    api.get(url).then((response) => {
      if (response.data.code === 0) {
        setData(false)
      } else {
        setData(response.data)
      }
    })
  }, [loading, params])

  return (
    <div id="root-project" className="root-page">
      {data === false && <Redirect to="/projetos" />}

      {data.page && (
        <>
          <Header data={data.options} />

          <main id="page-project" className="page">
            <SectionHero data={data} />
            <SectionContent data={data} />
            <SectionRelated data={data} />
          </main>

          <Footer data={data.options} />
        </>
      )}
    </div>
  )
}

export default Project
