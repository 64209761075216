import React, { useState, useEffect } from 'react'
import { Parallax } from 'react-scroll-parallax'
import './SectionStyle.scss'

const SectionVideo = ({ data }) => {
  const [videoIsActive, setActiveVideo] = useState()

  useEffect(() => {
    const video = document.querySelector('.video-full')

    video.addEventListener('click', function (e) {
      setActiveVideo(false)
    })
  }, [])

  useEffect(() => {
    const video = document.querySelector('.video-full')

    if (videoIsActive) {
      video.classList.add('active')

      const url = 'https://www.youtube.com/embed'

      const iframe = document.createElement('iframe')
      iframe.setAttribute('src', url + '/' + data.page.showreel.video)
      iframe.setAttribute('frameborder', '0')
      iframe.setAttribute(
        'allow',
        'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
      )
      iframe.setAttribute('allowfullscreen', 'true')

      video.querySelector('.video-content').appendChild(iframe)
    } else if (video.querySelector('.video-content iframe')) {
      video.classList.remove('active')

      setTimeout(
        () => video.querySelector('.video-content iframe').remove(),
        700,
      )
    }
  }, [data, videoIsActive])

  return (
    <section className="section section-video">
      <div className="container">
        <div className="video">
          <Parallax
            className="video-circle"
            scale={[0, 1]}
            rootMargin={{
              top: 100,
              bottom: -300,
            }}
          >
            <div className="video-image"></div>
          </Parallax>

          <div className="video-play">
            <Parallax
              className="video-play--bg"
              opacity={[0, 1]}
              scale={[1, 0.5]}
              rootMargin={{
                top: -400,
                bottom: -400,
              }}
            ></Parallax>

            <button
              className="video-play--button"
              title="Play"
              aria-label="Play"
              onClick={() => setActiveVideo(true)}
            >
              Play
            </button>

            <div className="video-play--text">
              <span
                dangerouslySetInnerHTML={{
                  __html: data.page.showreel.title,
                }}
              ></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionVideo
