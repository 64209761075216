import React from 'react'
import { Parallax } from 'react-scroll-parallax'
import './SectionStyle.scss'

import Image from 'components/general/Image'

import { ReactComponent as HeroArrowDown } from 'assets/vectors/general/arrow-down.svg'

const SectionHero = ({ data }) => {
  return (
    <section className="section section-hero">
      <div className="container">
        <div className="flex-wrapper">
          <div className="wrapper">
            <div className="wrapper-logo" appear="0" appear-delay="250">
              <Image
                url={data.page.hero.logo.image}
                webp={data.page.hero.logo.imageWEBP}
                avif={data.page.hero.logo.imageAVIF}
              />
            </div>

            <div className="wrapper-flex" appear="0" appear-delay="50">
              <div className="left">
                <div className="left-title">
                  {data.page.hero.title.split(' ').map((item, index) => (
                    <span key={`hero-title-${index}`}>
                      <s>{item}</s>
                    </span>
                  ))}
                </div>

                <div className="left-arrow">
                  <HeroArrowDown />
                </div>
              </div>

              <div className="right">
                <div className="right-description">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: data.page.hero.description,
                    }}
                  ></p>
                </div>

                <div className="right-arrow">
                  <HeroArrowDown />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="image" appear="0" appear-delay="100">
        <Parallax
          className="image-block"
          scale={[1.5, 1]}
          rootMargin={{
            top: 0,
            bottom: -600,
          }}
        >
          <Image
            url={data.page.hero.image.image}
            webp={data.page.hero.image.imageWEBP}
            avif={data.page.hero.image.imageAVIF}
          />
        </Parallax>
      </div>
    </section>
  )
}

export default SectionHero
