import React, { useState } from 'react'
import './SectionStyle.scss'

import Image from 'components/general/Image'

import { ReactComponent as FilterIcon } from 'assets/vectors/home/filter-option.svg'
import { ReactComponent as HeadlineIcon } from 'assets/vectors/general/filter-headline.svg'

import phoneImage from 'assets/images/home/filter-phone.png'

const SectionFilters = ({ data }) => {
  const total = data.page.filters.content.length
  const [tab, setTab] = useState(0)

  return (
    <section className="section section-filters">
      <div className="container">
        <div className="wrapper">
          <div className="headline" appear="0.5">
            <div className="headline-title">{data.page.filters.title}</div>

            <HeadlineIcon />
          </div>

          <div className="phone">
            <div className="phone-image">
              <Image url={phoneImage} />
            </div>

            <div className="phone-tabs">
              {data.page.filters.content.map((item, index) => (
                <div
                  className={tab === index ? 'item active' : 'item'}
                  data-index={index}
                  key={`home-filters-tab-${index}`}
                >
                  <div className="item-image">
                    <Image
                      url={item.image.image}
                      webp={item.image.imageWEBP}
                      avif={item.image.imageAVIF}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div
            className="arrow-left"
            onClick={() => setTab(tab === 0 ? total - 1 : tab - 1)}
          ></div>

          <div
            className="arrow-right"
            onClick={() => setTab(tab === total - 1 ? 0 : tab + 1)}
          ></div>
        </div>

        <div className="options">
          {data.page.filters.content.map((item, index) => (
            <div
              className={tab === index ? 'option active' : 'option'}
              data-index={index}
              key={`home-filters-content-${index}`}
            >
              <a
                href={item.link}
                className="option-link"
                title="Use este filtro"
                aria-label="Use este filtro"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FilterIcon />
                <span>Use este filtro</span>
              </a>

              <div className="option-content">
                <div className="option-content--title">{item.title}</div>

                <div className="option-content--subtitle">{item.subtitle}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default SectionFilters
