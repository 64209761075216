import React from 'react'
import { Link } from 'react-router-dom'
import { Parallax } from 'react-scroll-parallax'
import './SectionStyle.scss'

import Image from 'components/general/Image'

import { ReactComponent as ArrowRight } from 'assets/vectors/general/arrow-right.svg'

const SectionRelated = ({ data }) => {
  return (
    <section className="section section-related">
      <div className="container">
        <div className="headline">
          <div className="headline-title">
            {data.options['project-related-title']}
          </div>
        </div>

        <div className="flex-wrapper">
          {data.page.related.map((item, index) => (
            <div className="item" key={`related-item-${index}`}>
              <Link
                to={`/projeto/${item.permalink}`}
                className="item-link"
                title="Ver projeto"
                aria-label="Ver projeto"
              >
                Ver projeto
              </Link>

              <div className="item-image">
                <Parallax
                  className="item-image--block"
                  scale={[1.5, 1]}
                  rootMargin={{
                    top: 0,
                    bottom: -400,
                  }}
                >
                  <Image
                    url={item.image.image}
                    webp={item.image.imageWEBP}
                    avif={item.image.imageAVIF}
                  />
                </Parallax>
              </div>

              <div className="item-content">
                <div className="item-content--title">{item.title}</div>

                <div className="item-content--arrow">
                  <ArrowRight />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default SectionRelated
