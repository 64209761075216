import React from 'react'

const Image = ({
  url,
  alt = 'Imagem - Matheus Pinheiro',
  webp = false,
  avif = false,
  parallax = false,
}) => {
  return (
    <picture>
      {avif && <source srcSet={avif} type="image/avif" />}
      {webp && <source srcSet={webp} type="image/webp" />}

      <img src={url} alt={alt} />
    </picture>
  )
}

export default Image
