import React, { useRef, useState, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { Parallax } from 'react-scroll-parallax'
import './SectionStyle.scss'

import Image from 'components/general/Image'

import { ReactComponent as HeadlineIcon } from 'assets/vectors/general/awards-headline.svg'
import { ReactComponent as ArrowRight } from 'assets/vectors/general/arrow-right.svg'

const SectionAwards = ({ data }) => {
  const element = useRef(null)
  const [project, setProject] = useState(0)

  const setupClass = (current, index) => {
    const total = data.page.work.images.length
    let classes = 'item'

    if (current === index - 1) {
      classes = classes + ' remove'
    }

    if (current === index) {
      classes = classes + ' active'
    }

    if (current - 1 === index) {
      classes = classes + ' next'
    }

    if (current - 2 === index) {
      classes = classes + ' next-2'
    }

    if (current - 3 === index) {
      classes = classes + ' next-3'
    }

    if (current === 0 && index === total - 1) {
      classes = classes + ' next'
    }

    if (current === 0 && index === total - 2) {
      classes = classes + ' next-2'
    }

    if (current === 0 && index === total - 3) {
      classes = classes + ' next-3'
    }

    if (current === 1 && index === total - 1) {
      classes = classes + ' next-2'
    }

    if (current === 1 && index === total - 2) {
      classes = classes + ' next-3'
    }

    if (current === 2 && index === total - 1) {
      classes = classes + ' next-3'
    }

    if (current === total - 1 && index === 0) {
      classes = classes + ' remove'
    }

    return classes
  }

  useEffect(() => {
    if (!element.current) return

    let index = 0

    element.current.addEventListener('appeared', function () {
      const interval = setInterval(function () {
        if (!element.current) {
          clearInterval(interval)
          return
        }

        setProject(index === 8 ? 0 : index)

        if (index + 1 === 8) {
          index = 0
          return
        }

        index++
      }, 2000)
    })
  }, [element])

  return (
    <section className="section section-awards">
      <div className="bg-orange">
        <Parallax
          className="bg-orange--parallax"
          opacity={[0, 1]}
          startScroll={isMobile ? 1800 : 3900}
          endScroll={isMobile ? 2600 : 4700}
        />

        <div className="container">
          <div className="headline">
            <div
              className="headline-small"
              appear="0.5"
              dangerouslySetInnerHTML={{
                __html: data.page.work.small,
              }}
            ></div>

            <div className="headline-wrapper">
              <div className="headline-title">
                <div className="headline-title--line" appear="0.3">
                  <span>{data.page.work.title1}</span>
                </div>

                <div className="headline-title--line" appear="0.3">
                  <span>{data.page.work.title2}</span>
                </div>

                <div className="headline-title--line" appear="0.3">
                  <span>{data.page.work.title3}</span>
                </div>
              </div>

              <div className="headline-projects" appear="0.4" ref={element}>
                {data.page.work.images.map((item, index) => (
                  <div
                    className={setupClass(index, project)}
                    key={`work-project-${index}`}
                  >
                    <div className="item-image">
                      <Image
                        url={item.image}
                        webp={item.imageWEBP}
                        avif={item.imageAVIF}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="headline-cta">
              <div className="headline-cta--title" appear="0.3">
                {data.page.work.award.title}
              </div>

              <div className="headline-cta--button" appear="0.3">
                <a
                  href={data.page.work.award.link}
                  title={data.page.work.award.text}
                  aria-label={data.page.work.award.text}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>{data.page.work.award.text}</span>
                  <ArrowRight />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-black">
        <div className="bg-black--parallax"></div>

        <div className="container">
          <div className="awards" appear="0.3">
            <div className="awards-headline" appear="0.3">
              <div className="awards-title">{data.page.nominations.title}</div>

              <HeadlineIcon />
            </div>

            <div className="awards-items">
              {data.page.nominations.content.map((item, index) => (
                <div className="item" key={`home-nominations-${index}`}>
                  <a
                    href={item.link}
                    className="item-link"
                    title="Ver prêmio"
                    aria-label="Ver prêmio"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ver prêmio
                  </a>

                  <div className="item-title">
                    <span>{item.title}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionAwards
