import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import Lottie from 'lottie-react'
import animation from './animation.json'
import './FooterStyle.scss'

import { ReactComponent as ArrowUp } from 'assets/vectors/general/arrow-up.svg'
import { ReactComponent as IconInsta } from 'assets/vectors/general/footer-icon-insta.svg'
import { ReactComponent as IconPinterest } from 'assets/vectors/general/footer-icon-pinterest.svg'
import { ReactComponent as IconLinkedin } from 'assets/vectors/general/footer-icon-linkedin.svg'
import { ReactComponent as IconYoutube } from 'assets/vectors/general/footer-icon-youtube.svg'

const Footer = ({ data, type = 'default' }) => {
  const [runAnimation, setRunAnimation] = useState(false)
  const background = useRef(null)

  useEffect(() => {
    if (!background.current) return

    background.current.addEventListener('appeared', function () {
      setRunAnimation(true)
    })
  }, [background])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  return (
    <footer id="main-footer">
      <div className="container">
        <div className="background" ref={background} appear="0.3">
          {runAnimation && <Lottie animationData={animation} loop={true} />}
        </div>

        {type !== 'about' && (
          <div className="flex-wrapper wrapper-1">
            <div className="left">
              <div className="left-title" appear="0.3">
                Receba atualizações
                <br />
                do curso ou downloads
                <br />
                exclusivos no seu e-mail
              </div>
            </div>

            <div className="right" appear="0.3">
              <form className="right-form">
                <div className="field-group">
                  <input
                    type="email"
                    name="email"
                    className="field"
                    placeholder="Endereço de Email"
                  />
                </div>

                <div className="form-submit">
                  <button type="button" title="Enviar" aria-label="Enviar">
                    <span>Enviar</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        <div className="flex-wrapper wrapper-2">
          {type !== 'about' && (
            <div className="scroll-top" appear="0.3">
              <button
                type="button"
                title="Ir para o topo"
                aria-label="Ir para o topo"
                onClick={() => scrollToTop()}
              >
                <span>PRA CIMA</span>
                <ArrowUp />
              </button>
            </div>
          )}

          <div className="social">
            <ul>
              <li appear="0.3" appear-delay="0">
                <a
                  href="https://www.instagram.com/matheuspinheiro/"
                  title="Ir para Instagram"
                  aria-label="Ir para Instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconInsta />
                </a>
              </li>

              <li appear="0.3" appear-delay="150">
                <a
                  href="https://pin.it/7QAcN2N"
                  title="Ir para Pinterest"
                  aria-label="Ir para Pinterest"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconPinterest />
                </a>
              </li>

              <li appear="0.3" appear-delay="300">
                <a
                  href="https://www.linkedin.com/in/matheuspinhero"
                  title="Ir para Linkedin"
                  aria-label="Ir para Linkedin"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconLinkedin />
                </a>
              </li>

              <li appear="0.3" appear-delay="450">
                <a
                  href="https://www.youtube.com/channel/UCB0uZkimoVeLWb2n7VTPZdQ"
                  title="Ir para Youtube"
                  aria-label="Ir para Youtube"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconYoutube />
                </a>
              </li>
            </ul>
          </div>

          <nav className="navigation">
            <ul>
              <li appear="0.3" appear-delay="0">
                <Link to="/projetos" title="Projetos" aria-label="Projetos">
                  Projetos
                </Link>
              </li>

              <li appear="0.3" appear-delay="150">
                <Link to="/sobre" title="Serviços" aria-label="Serviços">
                  Sobre
                </Link>
              </li>

              <li appear="0.3" appear-delay="300">
                <Link to="/contato" title="Contato" aria-label="Contato">
                  Contato
                </Link>
              </li>

              <li appear="0.3" appear-delay="450">
                <button>Cursos</button>
              </li>

              <li appear="0.3" appear-delay="600">
                <button>Downloads</button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  )
}

export default Footer
