import React, { useState, useEffect } from 'react'
import api from 'utils/api'
import './AboutStyle.scss'

import Header from 'components/general/Header'
import Footer from 'components/general/Footer'

import SectionHero from 'components/about/SectionHero'
import SectionAbout from 'components/about/SectionAbout'

const About = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})

  useEffect(() => {
    if (loading) return
    setLoading(true)

    const url = `?action=load_page_fields&page=about`

    api.get(url).then((response) => {
      setData(response.data)
    })
  }, [loading])

  return (
    <div id="root-about" className="root-page" appear="0">
      {data.page && (
        <>
          <Header data={data.options} />

          <main id="page-about" className="page">
            <SectionHero data={data} />
            <SectionAbout data={data} />
          </main>

          <Footer data={data.options} type="about" />
        </>
      )}
    </div>
  )
}

export default About
