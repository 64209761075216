import React from 'react'
import './SectionStyle.scss'

import Image from 'components/general/Image'

const SectionContent = ({ data }) => {
  return (
    <section className="section section-content">
      <div className="container">
        <div className="info">
          <div className="info-item" appear="0" appear-delay="0">
            <div className="info-item--title">Serviço</div>

            <div className="info-item--subtitle">{data.page.content.info1}</div>
          </div>

          <div className="info-item" appear="0" appear-delay="150">
            <div className="info-item--title">Cliente</div>

            <div className="info-item--subtitle">{data.page.content.info2}</div>
          </div>

          <div className="info-item" appear="0" appear-delay="300">
            <div className="info-item--title">Ano</div>

            <div className="info-item--subtitle">{data.page.content.info3}</div>
          </div>
        </div>

        <div className="description" appear="0.3">
          <p
            dangerouslySetInnerHTML={{ __html: data.page.content.description }}
          ></p>
        </div>
      </div>

      <div className="wrapper">
        {data.page.gallery.map((item, index) => (
          <div className="wrapper-content" key={`gallery-item-${index}`}>
            {item.type === 'description' && (
              <div className="description" appear="0.3">
                <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
              </div>
            )}

            {item.type === 'full' && (
              <div className="wrapper-image--full">
                <div className="image-block">
                  <Image
                    url={item.image.image}
                    webp={item.image.imageWEBP}
                    avif={item.image.imageAVIF}
                  />
                </div>
              </div>
            )}

            {item.type === 'container' && (
              <div className="wrapper-image--full container">
                <div className="image-block">
                  <Image
                    url={item.image.image}
                    webp={item.image.imageWEBP}
                    avif={item.image.imageAVIF}
                  />
                </div>
              </div>
            )}

            {item.type === 'flex' && (
              <div className="wrapper-flex container">
                <div className="image">
                  <div className="image-block">
                    <Image
                      url={item.image1.image}
                      webp={item.image1.imageWEBP}
                      avif={item.image1.imageAVIF}
                    />
                  </div>
                </div>

                <div className="image">
                  <div className="image-block">
                    <Image
                      url={item.image2.image}
                      webp={item.image2.imageWEBP}
                      avif={item.image2.imageAVIF}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  )
}

export default SectionContent
