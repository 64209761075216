import React from 'react'
import { useLocation } from 'react-router-dom'

const PageViews = () => {
  const location = useLocation()

  React.useEffect(() => {
    const body = document.querySelector('body')
    const url = location.pathname.split('/')
    const path = url.filter(function (entry) {
      return entry.trim() !== ''
    })

    setTimeout(() => window.scrollTo(0, 0), 1300)

    body.setAttribute('data-page', path)
  }, [location])

  return ''
}

export default PageViews
