import { useEffect } from 'react'

const LazyLoad = () => {
  const animate = (entries) => {
    entries.forEach((entry) => {
      const item = entry.target
      const delay = item.getAttribute('data-delay') || 0
      const parent = item.getAttribute('data-parent')

      if (item.innerHTML.length > 0) return
      if (!entry.isIntersecting) return
      if (item.classList.contains('loading')) return
      if (item.classList.contains('loaded')) return

      setTimeout(function () {
        const url = item.getAttribute('data-url')
        const webp = item.getAttribute('data-webp')
        const avif = item.getAttribute('data-avif')

        const picture = document.createElement('picture')

        if (avif.length > 0) {
          const avifElement = document.createElement('source')
          avifElement.srcset = avif
          avifElement.type = 'image/avif'

          picture.appendChild(avifElement)
        }

        if (webp.length > 0) {
          const webpElement = document.createElement('source')
          webpElement.srcset = webp
          webpElement.type = 'image/webp'

          picture.appendChild(webpElement)
        }

        const image = new Image()
        image.src = url
        image.alt = 'Image - Atacarejão do Lar'

        picture.appendChild(image)

        item.classList.add('loading')
        item.dispatchEvent(new Event('appeared'))

        if (item.innerHTML.length > 0) return

        item.appendChild(picture)

        image.onload = function () {
          setTimeout(function () {
            item.classList.add('loaded')

            if (parent) {
              item.closest(parent).classList.add('loaded')
            }
          }, 100)

          setTimeout(function () {
            item.removeAttribute('rel')
            item.removeAttribute('data-url')
            item.removeAttribute('data-distance')
            item.removeAttribute('data-delay')
            item.removeAttribute('data-webp')
            item.removeAttribute('data-avif')
          }, 2000)
        }
      }, delay)
    })
  }

  useEffect(() => {
    const body = document.querySelector('body')

    const lazyLoad = (type) => {
      const lazies = document.querySelectorAll('[rel="' + type + '"]')

      for (let i = 0; i < lazies.length; i++) {
        const item = lazies[i]
        const distance = Number(item.getAttribute('data-distance'))

        new window.IntersectionObserver((entries) => animate(entries), {
          threshold: distance,
        }).observe(item)
      }
    }

    body.addEventListener('pageChanged', () => lazyLoad('lazy_load'))
    body.addEventListener('lazyLoad', () => lazyLoad('lazy_load_new'))
  }, [])

  return ''
}

export default LazyLoad
