import React from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'
import ReactDOM from 'react-dom'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Appear from 'utils/Appears'
import LazyLoad from 'utils/LazyLoad'
import Parallax from 'utils/Parallax'
import PageViews from 'utils/PageViews'

import './sass/main.scss'

import Preloader from 'components/general/Preloader'

import Home from 'container/home'
import About from 'container/about'
import Contact from 'container/contact'
import Projects from 'container/projects'
import Project from 'container/project'

const Routes = () => {
  const animateElements = () => {
    const body = document.querySelector('body')

    setTimeout(() => body.dispatchEvent(new Event('pageChanged')), 700)
  }

  return (
    <ParallaxProvider>
      <BrowserRouter>
        <PageViews />
        <Appear />
        <LazyLoad />
        <Parallax />

        <Preloader />

        <Switch>
          <Route
            render={({ location }) => (
              <TransitionGroup className="page-transition">
                <CSSTransition
                  key={location.pathname}
                  timeout={2500}
                  classNames="transition"
                  onEntered={() => animateElements()}
                >
                  <Switch location={location}>
                    <Route path="/" exact={true} component={Home} />
                    <Route path="/sobre" component={About} />
                    <Route path="/contato" component={Contact} />
                    <Route path="/projetos" component={Projects} />
                    <Route path="/projeto/:projeto" component={Project} />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            )}
          />
        </Switch>
      </BrowserRouter>
    </ParallaxProvider>
  )
}

ReactDOM.render(<Routes />, document.getElementById('root'))
