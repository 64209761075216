import React from 'react'
import { Link } from 'react-router-dom'
import './SectionStyle.scss'

import { ReactComponent as HeroArrowDown } from 'assets/vectors/general/arrow-down.svg'
import heroTitle1 from 'assets/vectors/projects/hero-title-1.svg'

const SectionHero = ({ data }) => {
  return (
    <section className="section section-hero">
      <div className="container">
        <div className="flex-wrapper">
          <div className="wrapper" appear="0" appear-delay="200">
            <div className="wrapper-description">
              <div className="wrapper-description--line">
                {'Art Director &'.split(' ').map((item, index) => (
                  <span key={`hero-wrapper-1-${index}`}>{item}</span>
                ))}
              </div>

              <div className="wrapper-description--line">
                {'Motion Graphic Designer'.split(' ').map((item, index) => (
                  <span key={`hero-wrapper-2-${index}`}>{item}</span>
                ))}
              </div>

              <div className="wrapper-description--line">
                {'Mineiro em Belo Horizonte'.split(' ').map((item, index) => (
                  <span key={`hero-wrapper-3-${index}`}>{item}</span>
                ))}
              </div>

              <div className="wrapper-description--links">
                <span>
                  <Link to="/projetos" title="Projetos" aria-label="Projetos">
                    Working
                  </Link>
                </span>

                <span>/</span>

                <span>
                  <a href="mailto:contato@pinheiro.work">Mail</a>
                </span>

                <span>/</span>

                <span>
                  <a
                    href="https://www.instagram.com/matheuspinheiro/"
                    title="Ir para Instagram"
                    aria-label="Ir para Instagram"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    IG
                  </a>
                </span>

                <span>/</span>

                <span>
                  <a
                    href="https://www.linkedin.com/in/matheuspinhero"
                    title="Ir para Linkedin"
                    aria-label="Ir para Linkedin"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Linkedin
                  </a>
                </span>
              </div>
            </div>

            <div className="wrapper-arrow">
              <HeroArrowDown />
            </div>
          </div>

          <div className="content">
            <div className="content-title">
              <div className="content-title--line" appear="0.3">
                <img src={heroTitle1} alt="Imagem - Matheus Pinheiro" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionHero
