import React, { useState, useEffect } from 'react'
import api from 'utils/api'
import './HomeStyle.scss'

import Header from 'components/general/Header'
import Footer from 'components/general/Footer'
import SectionServices from 'components/general/SectionServices'

import SectionHero from 'components/home/SectionHero'
import SectionVideo from 'components/home/SectionVideo'
import SectionProjects from 'components/home/SectionProjects'
import SectionAwards from 'components/home/SectionAwards'
import SectionPartners from 'components/home/SectionPartners'
import SectionFilters from 'components/home/SectionFilters'

const Home = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})

  useEffect(() => {
    if (loading) return
    setLoading(true)

    const url = `?action=load_page_fields&page=home`

    api.get(url).then((response) => {
      console.log(response.data)

      setData(response.data)
    })
  }, [loading])

  return (
    <div id="root-home" className="root-page">
      {data.page && (
        <>
          <Header data={data.options} />

          <div className="video-full">
            <div className="video-content"></div>
          </div>

          <main id="page-home" className="page">
            <SectionHero data={data} />
            <SectionVideo data={data} />
            <SectionProjects data={data} />
            <SectionServices data={data} />
            <SectionAwards data={data} />
            <SectionPartners data={data} />
            <SectionFilters data={data} />
          </main>

          <Footer data={data.options} />
        </>
      )}
    </div>
  )
}

export default Home
