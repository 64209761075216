import React, { useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './HeaderStyle.scss'

import { ReactComponent as LogoIcon } from 'assets/vectors/general/logo.svg'

const Header = () => {
  const header = useRef(null)

  useEffect(() => {
    const element = header.current

    window.addEventListener('scroll', function () {
      const offset = window.scrollY

      if (offset > 10) {
        if (!element.classList.contains('sticky')) {
          element.classList.add('sticky')
        }
      } else {
        if (element.classList.contains('sticky')) {
          element.classList.remove('sticky')
        }
      }
    })

    window.addEventListener('wheel', function (e) {
      const offset = window.scrollY
      const deltaY = e.deltaY

      if (deltaY > 3 && offset > 400) {
        if (!element.classList.contains('remove')) {
          element.classList.add('remove')
        }
      } else if (deltaY < -3) {
        if (element.classList.contains('remove')) {
          element.classList.remove('remove')
        }
      }
    })
  }, [header])

  return (
    <header id="main-header" ref={header}>
      <div className="container">
        <div className="flex-wrapper">
          <div className="logo" appear="0" appear-delay="350">
            <Link
              to="/"
              href="#link"
              title="Ir para a página inicial"
              aria-label="Ir para a página inicial"
            >
              <LogoIcon />
              <LogoIcon />
            </Link>
          </div>

          <nav className="navigation">
            <ul>
              <li appear="0" appear-delay="350">
                <Link
                  to="/projetos"
                  title="Ir para Projetos"
                  aria-label="Ir para Projetos"
                >
                  <span>
                    {'Projetos'.split('').map((item, index) => (
                      <s key={`menu-item-1-1-letter-${index}`}>{item}</s>
                    ))}
                  </span>

                  <span>
                    {'Projetos'.split('').map((item, index) => (
                      <s key={`menu-item-1-2-letter-${index}`}>{item}</s>
                    ))}
                  </span>
                </Link>
              </li>

              <li appear="0" appear-delay="500">
                <Link
                  to="/sobre"
                  title="Ir para página de Sobre"
                  aria-label="Ir para página de Sobre"
                >
                  <span>
                    {'Sobre'.split('').map((item, index) => (
                      <s key={`menu-item-2-1-letter-${index}`}>{item}</s>
                    ))}
                  </span>

                  <span>
                    {'Sobre'.split('').map((item, index) => (
                      <s key={`menu-item-2-2-letter-${index}`}>{item}</s>
                    ))}
                  </span>
                </Link>
              </li>

              <li appear="0" appear-delay="650">
                <Link
                  to="/contato"
                  title="Entrar em Contato"
                  aria-label="Entrar em Contato"
                >
                  <span>
                    {'Contato'.split('').map((item, index) => (
                      <s key={`menu-item-3-1-letter-${index}`}>{item}</s>
                    ))}
                  </span>

                  <span>
                    {'Contato'.split('').map((item, index) => (
                      <s key={`menu-item-3-2-letter-${index}`}>{item}</s>
                    ))}
                  </span>
                </Link>
              </li>

              <li appear="0" appear-delay="800">
                <button>
                  <span>
                    {'Cursos'.split('').map((item, index) => (
                      <s key={`menu-item-3-1-letter-${index}`}>{item}</s>
                    ))}
                  </span>

                  <span>
                    {'Cursos'.split('').map((item, index) => (
                      <s key={`menu-item-3-2-letter-${index}`}>{item}</s>
                    ))}
                  </span>
                </button>
              </li>

              <li appear="0" appear-delay="950">
                <button>
                  <span>
                    {'Downloads'.split('').map((item, index) => (
                      <s key={`menu-item-3-1-letter-${index}`}>{item}</s>
                    ))}
                  </span>

                  <span>
                    {'Downloads'.split('').map((item, index) => (
                      <s key={`menu-item-3-2-letter-${index}`}>{item}</s>
                    ))}
                  </span>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  )
}

export default Header
