import React, { useState, useEffect } from 'react'
import api from 'utils/api'
import './ProjectsStyle.scss'

import Header from 'components/general/Header'
import Footer from 'components/general/Footer'
import SectionServices from 'components/general/SectionServices'

import SectionHero from 'components/projects/SectionHero'
import SectionProjects from 'components/projects/SectionProjects'
import SectionLaboratory from 'components/projects/SectionLaboratory'

const Projects = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})

  useEffect(() => {
    if (loading) return
    setLoading(true)

    const url = `?action=load_page_fields&page=projects`

    api.get(url).then((response) => {
      setData(response.data)
    })
  }, [loading])

  return (
    <div id="root-projects" className="root-page">
      {data.page && (
        <>
          <Header data={data.options} />

          <main id="page-projects" className="page">
            <SectionHero data={data} />
            <SectionProjects data={data} />
            <SectionServices data={data} />
            <SectionLaboratory data={data} />
          </main>

          <Footer data={data.options} />
        </>
      )}
    </div>
  )
}

export default Projects
